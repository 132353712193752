<template>
  <div class="container">
    <div class="main">
      <div class="bkbox" v-if="list.length > 0">
        <div class="title">违法记录</div>
        <ul
          class="bkul"
          @click="onClickTheLaw(item)"
          v-for="(item, index) in list"
          :key="index"
        >
          <li>
            <div>{{ item.companyName }}</div>
            <div class="bktxt">{{ item.plateNo }}</div>
          </li>
          <li>{{ item.punishResult }}</li>
          <li>{{ item.punishDate }}</li>
        </ul>
      </div>
      <div class="bkbox">
        <div class="title">过户信息概要</div>
        <ul class="ghbox">
          <li>本车共{{ content.transferNum }}次过户</li>
          <!-- <li>
            <div>第一次过户</div>
            <div>上海 | 2022-07-29</div>
          </li> -->
          <li>
            <div>首次登记</div>
            <div>{{ content.transferDate }}</div>
          </li>
        </ul>
      </div>
      <div class="bkbox">
        <div class="title">投保状态</div>
        <ul class="insul">
          <li>
            <div>交强险</div>
            <div>{{ content.traffic1 ? "在保" : "已过期" }}</div>
          </li>
          <li>
            <div>交强险持续同一公司</div>
            <div>{{ content.traffic2 ? "同一公司" : "非同一公司" }}</div>
          </li>
          <li>
            <div>交强险投保人与收益人是否一致</div>
            <div>{{ content.traffic3 ? "一致" : "不一致" }}</div>
          </li>
          <li>
            <div>商业险</div>
            <div>{{ content.business1 ? "在保" : "已过期" }}</div>
          </li>
          <li>
            <div>商业险持续同一公司</div>
            <div>{{ content.business2 ? "同一公司" : "非同一公司" }}</div>
          </li>
          <li>
            <div>商业险投保人与收益人是否一致</div>
            <div>{{ content.business3 ? "一致" : "不一致" }}</div>
          </li>
          <li>
            <div>车船险</div>
            <div>{{ content.vehicleAndVessel ? "在保" : "已过期" }}</div>
          </li>
        </ul>
      </div>
    </div>
    <ul class="ul-up">
      <li @click="backPage">上一步</li>
      <li @click="nextPage">输出报告</li>
    </ul>
  </div>
</template>

<script>
import {
  illegalGetList,
  riskCroGetOtherInfo,
  riskCroGetResult,
} from "@/utils/http.js"; //路径注意

export default {
  data() {
    return {
      list: [],
      content: {},
    };
  },
  created() {
    this.getList();
  },
  mouted() {},
  methods: {
    backPage() {
      this.$router.go(-1);
    },
    async nextPage() {
      let str = localStorage.getItem("valuationInfo") || false;
      let content = JSON.stringify(this.content);
      let list = JSON.stringify(this.list);
      let valuationInfo = JSON.parse(str);
      let obj = {
        plateNo: valuationInfo.PlateNo,
        vin: valuationInfo.Vin,
        VehicleType: valuationInfo.VehicleType,
        vehicleModel: valuationInfo.vehicleModel,
        RegisterDate: valuationInfo.RegisterDate,
        carType: valuationInfo.carType,
        natureUse: valuationInfo.UseCharacter,
        engineBrand: valuationInfo.engineBrand,
        engineModel: valuationInfo.engineModel,
        techExtend: valuationInfo.techExtend,
        goodsType: valuationInfo.goodsType,
        truck: localStorage.getItem("truckInfo"),
        insurance: localStorage.getItem("insuranceInfo"),
        otherInfo: content,
        illegal: list,
      };
      // 000051
      const res = await riskCroGetResult(obj);
      if (res.data.resultStates == 0) {
        localStorage.setItem("objMsgInfo", JSON.stringify(obj));
        localStorage.setItem("resultInfo", JSON.stringify(res.data.content));
        this.$router.push("/insurance/report/v1");
      }
    },
    onClickTheLaw(data) {
      this.$router.push("/insurance/traffic/v1?id=" + data.id);
    },
    async getList() {
      let str = localStorage.getItem("valuationInfo");
      let valuationInfo = JSON.parse(str);
      this.valuationInfo = valuationInfo;
      let obj = {
        plateNo: valuationInfo.PlateNo,
        vin: valuationInfo.Vin,
      };
      let pa = {
        pageNum: 1,
        pageSize: 20,
        searchStr: valuationInfo.PlateNo,
      };
      const res = await illegalGetList(pa);
      this.list = res.data.data.result.items;
      const re = await riskCroGetOtherInfo(obj);
      this.content = re.data.content;
    },
  },
};
</script>
<style scoped>
.container {
  background: #eeeeee;
  padding: 10px 0;
  padding-bottom: 0px;
  height: 100vh;
}
.main {
  height: 100%;
  box-sizing: border-box;
  padding: 0 10px;
}
.title {
  font-size: 22px;
  font-weight: bold;
}
.bkbox {
  font-size: 14px;
  background: #ffffff;
  box-sizing: border-box;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
}
.bkul {
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 4px;
  margin-top: 10px;
  font-size: 12px;
}
.bkul > li:nth-of-type(1) {
  display: flex;
  align-content: center;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 8px;
}
.bktxt {
  color: #2d6af6;
  margin-left: 10px;
}
.bkul > li:nth-of-type(2) {
  margin: 10px 0;
}
.bkul > li:nth-of-type(3) {
  color: #999999;
}
.ul-up {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
  /* border: 1px solid #b7b7b7; */
  background: #ffffff;
}
.ul-up > li {
  line-height: 40px;
}
.ul-up > li:nth-of-type(1) {
  color: #999999;
  flex: 1;
}
.ul-up > li:nth-of-type(2) {
  flex: 2;
  background: #2d6af6;
  color: #ffffff;
  border-radius: 4px;
}
.ghbox {
  border: 1px solid #eeeeee;
  margin-top: 10px;
  border-radius: 4px;
}
.ghbox > li {
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  align-content: center;
  justify-content: space-between;
}
.ghbox > li:nth-of-type(1) {
  background: #f7f7fb;
  border-bottom: 1px solid #eeeeee;
  font-size: 16px;
}
.insul {
  border: 1px solid #eeeeee;
  margin-top: 10px;
  border-radius: 4px;
  background: #f7f7fb;
  border-top: none;
}
.insul > li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #eeeeee;
}
</style>
